import { useState } from "preact/hooks";

export default function Button(
  { text, link }: { text: string; link?: string },
) {
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    const form = document.forms[0];
    if (form) {
      setLoading(true);
      form.submit();
    } else {
      window.location.href = link!;
    }
  };

  return (
    <>
      <button
        className="action"
        onClick={handleClick}
        disabled={loading}
      >
        {loading ? "PLEASE WAIT.." : text}
      </button>
    </>
  );
}
